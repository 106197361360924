@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbars for all browsers */
.scrollbar-hidden {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, and Opera */
}

.dashboard::-webkit-scrollbar,
.events::-webkit-scrollbar {
  display: none !important;
}

.bgd-purple {
  background: linear-gradient(180deg, #3C4392 0%, rgba(14, 33, 239, 0.8) 100%);
}

.bg-primary-color {
  background-color: #3C4392;
}

.bg-dpurple-color {
  background-color: #3c439214;
}

span svg {
  stroke-width: 2px !important;
}

.comp::-webkit-scrollbar {
  display: none !important;
}


.bg-bck {
  background-color: #f6f6f6;
}

.bg-light {
  background: #fff;
}


:root {
  --purple: #3C4392;
  --gray: #cccccc;
}

body {
  font-family: 'Work Sans', sans-serif;
}

.select-wrapper select {
  -webkit-appearance: none;
  appearance: none;
}

.spacer {
  display: block;
  border-spacing: 10pt !important;
}

.border-gray-color {
  border-color: var(--gray);
}

.border-purple-color {
  border-color: var(--purple);
}

/* .bg-[#00aaff] {
  background-color: var(--purple) !important;
} */

.bg-brand-lpurple {
  background-color: #3c439265 !important;
}

.disabled-lt {
  background-color: #3c439255 !important;
  pointer-events: none !important;
}

.disabled {
  background-color: #25AAE1 !important;
}

.text-purple-color {
  color: var(--purple) !important;
}

.text-purple {
  color: #3C4392 !important;
}

.heighted {
  overflow-y: scroll;
}

.heighted::-webkit-scrollbar {
  display: none;
}

.layered {
  position: absolute;
  width: 100%;
  height: 200px;
  background-color: #fff;
  left: 0px;
  /* z-index: 2; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.pie-layered {
  /* position: absolute; */
  width: 100%;
  height: 200px;
  background-color: #fff;
  left: 0px;
  z-index: 2;
  display: flex;

}

.pie-layered .recharts-responsive-container {
  /* width: 100% !important; */
  /* height: 100% !important;
  min-width: 100px !important; */
}

.recharts-legend-wrapper {
  position: absolute;
  width: auto;
  height: auto;
  right: 10px !important;
  bottom: 28px !important;
}

.pie-layered .recharts-legend-wrapper {
  position: absolute;
  width: auto;
  height: auto;
  right: 110px !important;
  bottom: 68px !important;
}

thead tr th:first-child:has(input) {
  width: 0.5rem !important;
}

.accomodation::-webkit-scrollbar {
  display: none;
}

.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #3C4392 !important;
  color: #ffffff !important;
}

.bg-green-alert {
  background-color: #CEF2D4 !important;
}

.bg-red-alert {
  background-color: #F2CECE !important;
}

.text-green-alert {
  color: #017213 !important;
}

.text-icon-green {
  color: #CEF2D4;
}

.text-icon-red {
  color: #F2CECE;
}

.text-red-alert {
  color: #7d04045a !important;
}

.bg-green-icon-alert {
  background-color: #1E6429 !important;
}

.bg-red-icon-alert {
  background-color: #f80d0d !important;
}

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.5rem;        
}

/* link round */
.link-round {
  /* background-color: #FF631525; */
  width: 35px;
  height: 35px;
  display: inline-flex;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  align-items: center;
  justify-content: center;
  /* color: #000; */
  outline: none !important;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  transition: all 0.15s ease;
}


.link-round.smini {
  width: 19px;
  height: 19px;
}

.link-round.xmini {
  width: 16px;
  height: 16px;
}

.link-round.mini {
  width: 20px;
  height: 20px;
}

.link-round.minix {
  width: 25px;
  height: 25px;
}

.link-round.md {
  width: 40px;
  height: 40px;
}

.link-round.smd {
  width: 38px;
  height: 38px;
}

.link-round.sm {
  width: 30px;
  height: 30px;
}

.link-round.slg {
  width: 66px;
  height: 66px;
}

.link-round.xlg {
  width: 95px;
  height: 95px;
}

.link-round.cornered {
  border-radius: 5px;
}

.link-round.default {
  background-color: #ddd;
}

.link-round.ash {
  background-color: #f1f3fb;
}


input[type='checkbox'] {
  accent-color: #3C4392;
}

input:focus-visible {
  outline: 1.5px solid #d6f2ff;
}

.room {
  height: 100px;
  overflow-y: auto;
  flex-wrap: wrap;
}


@media (max-width: 576px) {

  .link-round.sm {
    height: 15.83px;
    width: 15.83px;
  } 

}


.vp-loader {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  color: #ffffff;
}

.vp-loader.yellow {
  color: #fbc530;
}

.vp-loader.orange {
  color: #FF6416;
}

.vp-loader::before,
.vp-loader::after {
  width: 30px;
  height: 30px;
  display: inline-flex;
  position: absolute;
  content: '';
  border-radius: 100px;
  top: 0;
  left: 0;
}

.vp-loader::before {
  border: 2px solid currentColor;
  opacity: .15;
}

.vp-loader::after {
  border: 2px solid;
  border-color: transparent;
  border-top-color: currentColor;

  -webkit-animation: spin-loader .6s linear;
  animation: spin-loader .6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.vp-loader.md,
.vp-loader.md::before,
.vp-loader.md::after {
  width: 25px;
  height: 25px;
}

.vp-loader.md::before {
  border: 0.1rem solid currentColor;
  opacity: .15;
}

.vp-loader.md::after {
  border: 0.1rem solid;
  border-color: transparent;
  border-top-color: currentColor;
}

.vp-loader.sm,
.vp-loader.sm::before,
.vp-loader.sm::after {
  width: 20px;
  height: 20px;
}

.vp-loader.sm::before {
  border: 0.1rem solid currentColor;
  opacity: .15;
}

.vp-loader.sm::after {
  border: 0.1rem solid;
  border-color: transparent;
  border-top-color: currentColor;
}

.vp-loader.lg,
.vp-loader.lg::before,
.vp-loader.lg::after {
  width: 35px;
  height: 35px;
}

.vp-loader.lg::before {
  border: 0.1rem solid currentColor;
  opacity: .15;
}

.vp-loader.lg::after {
  border: 0.1rem solid;
  border-color: transparent;
  border-top-color: currentColor;
}

.vp-loader.xlg,
.vp-loader.xlg::before,
.vp-loader.xlg::after {
  width: 50px;
  height: 50px;
}

.vp-loader.xlg::before {
  border: 0.1rem solid currentColor;
  opacity: .15;
}

.vp-loader.xlg::after {
  border: 0.1rem solid;
  border-color: transparent;
  border-top-color: currentColor;
}

@-webkit-keyframes spin-loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin-loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ui-dashboard-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f8fbff;
  border: 1px solid #d6f2ff;
  border-radius: 1rem;
}

.ui-dashboard-card-body {
  flex: 1 1 auto;
  margin: 0;
  padding: 2rem;
  position: relative;
  border-radius: 1.5rem;
}


/* react-tabs */
.react-tabs__tab-list {
  border-bottom: 1px solid #EBE4F8 !important;
}

.react-tabs__tab-list {
  display: flex;
  padding-inline: 0 !important;
}

.react-tabs__tab {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 3px 12px !important;
  padding-bottom: 12px;
  background: transparent !important;
  line-height: 23px;
  font-size: 14px;
  margin-right: 1.5rem;
  list-style: none;
  cursor: pointer;
  color: #15082F !important;
}

.react-tabs__tab--selected {
  border-radius: 0 !important;
  background: transparent;
  border-bottom: 3px solid #2ebefa !important;

  color: #2ebefa !important;
}

.react-tabs__tab:focus {
  outline: unset !important;
  border-bottom: 3px solid #2ebefa !important;
}


.react-tabs__tab:focus:after {
  background: transparent !important;
}